import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.REACT_APP_API_URL + 'quotation/'
const getQuotationPaging = async (param) => {
    return await axios.get(
        API_URL +
        'GetPaging?currentPage=' +
        param?.currentPage +
        '&perPage=' +
        param?.perPage +
        '&sortColumn=' +
        param?.sortColumn +
        '&sortDirection=' +
        param?.sortDirection +
        '&search=' +
        param?.search +
        '&menuCode=' +
        param?.menuCode +
        '&customerId=' +
        param?.customerId +
        '&quotationDateStart=' +
        param?.quotationDateStart +
        '&quotationDateEnd=' +
        param?.quotationDateEnd,
        { headers: authHeader() },
    )
}
const createQuotation = async (param) => {
    return await axios.post(API_URL + 'Create', param, { headers: authHeader() })
}
const updateQuotation = async (param) => {
    return await axios.post(API_URL + 'Update', param, { headers: authHeader() })
}
const deleteQuotation = async (param) => {
    return await axios.post(API_URL + 'Delete', param, { headers: authHeader() })
}
const getQuotationById = async (id) => {
    return await axios.get(API_URL + 'GetById?id=' + id, { headers: authHeader() })
}

export {
    getQuotationPaging,
    createQuotation,
    updateQuotation,
    deleteQuotation,
    getQuotationById,
}