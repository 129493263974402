const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    // Check if the pressed key is a digit or allowed control keys (backspace, delete)
    const isValidInput = /^[0-9]$/.test(keyValue) || [8, 46].includes(keyCode);

    // Prevent input if the pressed key is not valid
    if (!isValidInput) {
        event.preventDefault();
    }
};

const handleKeyDecimalPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    // Check if the pressed key is a digit, a decimal point, or allowed control keys (backspace, delete)
    const isValidInput = /^[0-9.]$/.test(keyValue) || [8, 46].includes(keyCode);

    // Prevent input if the pressed key is not valid
    if (!isValidInput) {
        event.preventDefault();
    }

    // Check for more than one decimal point
    if (keyValue === '.' && event.target.value.includes('.')) {
        event.preventDefault();
    }
};
const formatNumber = (number) => {
    return number.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};
const numberToThaiText = (number) => {
    const thaiNumbers = ['', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'];
    const thaiUnits = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'];

    const numberParts = number.toString().split('.');
    const integerPart = numberParts[0];
    const decimalPart = numberParts[1] || '';

    const convertPart = (numStr) => {
        let result = '';
        let unitIndex = 0;

        for (let i = numStr.length - 1; i >= 0; i--) {
            const digit = parseInt(numStr[i]);

            if (digit !== 0) {
                if (unitIndex === 1 && digit === 1) {
                    result = 'สิบ' + result;
                } else if (unitIndex === 1 && digit === 2) {
                    result = 'ยี่' + thaiUnits[unitIndex] + result;
                } else if (unitIndex > 0 && digit === 1 && result.startsWith('สิบ')) {
                    result = 'เอ็ด' + thaiUnits[unitIndex] + result.substring(2);
                } else {
                    result = thaiNumbers[digit] + thaiUnits[unitIndex] + result;
                }
            }
            unitIndex++;
        }

        return result;
    };

    let result = convertPart(integerPart) + 'บาท';

    if (decimalPart) {
        const satang = convertPart(decimalPart);
        result += satang ? satang + 'สตางค์' : 'ถ้วน';
    } else {
        result += 'ถ้วน';
    }

    return result;
};
export {
    handleKeyPress,
    handleKeyDecimalPress,
    formatNumber,
    numberToThaiText
}