import React, { useEffect, useState } from "react";
import { Table, Modal, Row, Col, Card, Form, Button, Space, Input, Select } from "antd";
import { getProductPaging } from "../services/product.service";
import Swal from "sweetalert2";
import { getProductCategorySelect } from "../services/category.service";
import { getProductUnitSelect } from "../services/unit.service";
import { getProductGroupSelect } from "../services/group.service";
import { getProductTypeSelect } from "../services/type.service";
import { getProductBrandSelect } from "../services/brand.service";

const ProductModal = ({ visible, onClose, onSelectProduct, inputValue }) => {
    const productColumns = [
        {
            title: "รหัสสินค้า",
            dataIndex: "productCode",
            key: "productCode",
            sorter: true,
        },
        {
            title: "ชื่อสินค้า",
            dataIndex: "productName",
            key: "productName",
            sorter: true,
        },
        {
            title: "กลุ่มสินค้า",
            dataIndex: "groupName",
            key: "groupName",
            sorter: true,
        },
        {
            title: "ประเภทสินค้า",
            dataIndex: "categoryName",
            key: "categoryName",
            sorter: true,
        },
        {
            title: "ชนิด",
            dataIndex: "typeName",
            key: "typeName",
            sorter: true,
        },
        {
            title: "ปริมาณสุทธิ",
            dataIndex: "productWeight",
            key: "productWeight",
            sorter: true,
        },
        {
            title: "หน่วยสินค้า",
            dataIndex: "unitName",
            key: "unitName",
            sorter: true,
        },
        {
            title: "รายละเอียด",
            dataIndex: "detail",
            key: "detail",
            sorter: true,
        },
    ];
    const menuCode = '';
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [sortField, setSortField] = useState();
    const [sortOrder, setSortOrder] = useState();
    const [search, setSearch] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [categoryId, setCategoryId] = useState()
    const [unitId, setUnitId] = useState()
    const [groupId, setGroupId] = useState()
    const [brandId, setBrandId] = useState()
    const [typeId, setTypeId] = useState()
    const [categoryList, setCategoryList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
            md: { span: 6 },
            lg: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
            md: { span: 14 },
            lg: { span: 14 },
        },
    }
    const loadDropdownList = async () => {
        const { data: category } = await getProductCategorySelect()
        if (category) {
            setCategoryList(category)
        }
        const { data: unit } = await getProductUnitSelect()
        if (unit) {
            setUnitList(unit)
        }
        const { data: group } = await getProductGroupSelect()
        if (group) {
            setGroupList(group)
        }
        const { data: type } = await getProductTypeSelect()
        if (type) {
            setTypeList(type)
        }
        const { data: brand } = await getProductBrandSelect()
        if (brand) {
            setBrandList(brand)
        }
    }
    useEffect(() => {
        if (visible) {
            console.log(inputValue);

            loadDropdownList();
            // Set initial values when the modal is opened
            form.setFieldsValue({ search: inputValue });
            setSearch(inputValue || '');
            fetchData({
                currentPage,
                sortColumn: sortField ?? '',
                sortDirection: sortOrder ?? '',
                perPage,
                search: inputValue || '',
                isClear: false,
            });
        } else {
            // Clear state when the modal closes
            clearSearch();
            clearSelection();
            form.resetFields();
            setSearch('');
            setData([]);
            setSelectedRow(null);
            setCurrentPage(1);
            setSortField(null);
            setSortOrder(null);
        }
    }, [visible]);

    const fetchData = async (param) => {
        let response = await getProductPaging({
            currentPage: param?.currentPage ?? 1,
            perPage: param?.perPage ?? 10,
            sortColumn: param?.sortColumn ?? '',
            sortDirection: param?.sortDirection ?? '',
            menuCode: menuCode ?? '',
            search: param.isClear ? '' : param.search || search,
            categoryId: param?.isClear ? '' : categoryId ?? '',
            unitId: param?.isClear ? '' : unitId ?? '',
            productGroupId: param?.isClear ? '' : groupId ?? '',
            typeId: param?.isClear ? '' : typeId ?? '',
            // supplierId: supplierId ?? '',
            brandId: param?.isClear ? '' : brandId ?? ''
        })
        if (response?.data) {
            setData(response?.data?.data)
            setTotalRows(response?.data?.total)
        }

    };

    const onSelect = () => {
        if (!selectedRow) {
            Swal.fire('กรุณาเลือกสินค้า', '', 'error');
            return;
        }
        onSelectProduct(selectedRow);
        onClose(); // Close the modal
    };

    const onChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setSortField(sorter.field);
        setSortOrder(sorter.order);
        setPerPage(pagination.pageSize);
    };

    const clearSearch = () => {
        setCurrentPage(1)
        setBrandId(null)
        setCategoryId(null)
        setGroupId(null)
        setSearch('')
        setBrandId(null)
        setTypeId(null)
        setUnitId(null)
        form.setFieldsValue({
            groupId: null,
            categoryId: null,
            unitId: null,
            typeId: null,
            supplierId: null,
            brandId: null,
            search: '',
        });
        fetchData({ isClear: true })
    };

    const clearSelection = () => {
        setSelectedRow(null);
        setSelectedRowKeys([]);
    };

    const handleRowClick = (record) => {
        setSelectedRow(record);
        setSelectedRowKeys([record.key]); // Assume `key` is a unique identifier
    };

    return (
        <Modal
            title="เลือกสินค้า"
            open={visible}
            onCancel={onClose}
            destroyOnClose={false}
            footer={[
                <Button key="back" type="primary" danger onClick={onClose}>
                    ยกเลิก
                </Button>,
                <Button key="submit" type="primary" style={{ backgroundColor: "#125a05" }} onClick={onSelect}>
                    เลือกสินค้า
                </Button>,
            ]}
            centered
            width={1400}
        >
            <Row className="row-padding-10">
                <Col span={24}>
                    <Card title="ค้นหา" bordered={false}>
                        <Form form={form}  {...formItemLayout} >
                            <Row gutter={24}>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label={`กลุ่มสินค้า`}
                                        name="groupId"
                                    >
                                        <Select
                                            placeholder="กรุณาเลือก"
                                            style={{ width: "100%" }}
                                            options={groupList}
                                            onChange={(e) => setGroupId(e)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label={`ประเภทสินค้า`}
                                        name="categoryId"
                                    >
                                        <Select
                                            placeholder="กรุณาเลือก"
                                            style={{ width: "100%" }}
                                            options={categoryList}
                                            onChange={(e) => setCategoryId(e)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label={`ชนิด`}
                                        name="typeId"
                                    >
                                        <Select
                                            placeholder="กรุณาเลือก"
                                            style={{ width: "100%" }}
                                            options={typeList}
                                            onChange={(e) => setTypeId(e)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label={`หน่วยสินค้า`}
                                        name="unitId"
                                    >
                                        <Select
                                            placeholder="กรุณาเลือก"
                                            style={{ width: "100%" }}
                                            options={unitList}
                                            onChange={(e) => setUnitId(e)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label={`ค้นหา`}
                                        name="search"
                                    >
                                        <Input
                                            onChange={(e) => setSearch(e.target.value)}
                                            placeholder="ค้นหา รหัสสินค้า , ชื่อสินค้า , รายละเอียด"
                                            value={search}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label={`ยี่ห้อ`}
                                        name="brandId"
                                    >
                                        <Select
                                            placeholder="กรุณาเลือก"
                                            style={{ width: "100%" }}
                                            options={brandList}
                                            onChange={(e) => setBrandId(e)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} className="mt-3">
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <Space size="middle">
                                        <Button type="primary" style={{ backgroundColor: "#125a05" }} size="large" onClick={() => fetchData({})}>
                                            ค้นหา
                                        </Button>
                                        <Button type="primary" danger size="large" onClick={clearSearch}>
                                            ล้าง
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
            <Row className="row-padding-10">
                <Col span={24}>
                    <Table
                        rowSelection={{
                            type: "radio",
                            selectedRowKeys,
                            onChange: (_, selectedRows) => {
                                setSelectedRow(selectedRows[0]);
                                setSelectedRowKeys([selectedRows[0]?.key]);
                            },
                        }}
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record),
                        })}
                        dataSource={data}
                        columns={productColumns}
                        onChange={onChange}
                        pagination={{
                            current: currentPage,
                            pageSize: perPage,
                            total: totalRows,
                        }}
                    />
                </Col>
            </Row>
        </Modal>
    );
};

export default ProductModal;
