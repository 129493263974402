import { Table, Tag, Button, Col, Row, Form, Input, Card, Select, Space, DatePicker } from "antd";
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import dayjs from 'dayjs';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { deleteQuotation, getQuotationPaging } from "../../services/quotation.service";
import { getCustomerSelect } from "../../services/customer.service";
import { render } from "@testing-library/react";
import { formatNumber } from "../../services/helper.service";
const { RangePicker } = DatePicker;
const QuotationList = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [data, setData] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [sortField, setSortField] = useState()
  const [sortOrder, setSortOrder] = useState()
  const [search, setSearch] = useState()
  const [dateRange, setDateRange] = useState(null);
  const [customerList, setCustomerList] = useState([])
  const [customerId, setCustomerId] = useState()
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
      md: { span: 6 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
      md: { span: 14 },
      lg: { span: 14 },
    },
  }
  const menuCode = ""
  useEffect(() => {
    loadDataDropDown();
    fetchData({
      currentPage: currentPage,
      sortColumn: sortField ?? '',
      sortDirection: sortOrder ?? '',
      perPage: perPage,
    })
  }, [currentPage, sortField, sortOrder, perPage])

  const fetchData = async (param) => {
    let response = await getQuotationPaging({
      currentPage: param?.currentPage ?? 1,
      perPage: param?.perPage ?? 10,
      sortColumn: param?.sortColumn ?? '',
      sortDirection: param?.sortDirection ?? '',
      menuCode: menuCode ?? '',
      search: param?.isClear ? '' : search ?? '',
      quotationDateStart: param?.isClear ? '' : dateRange?.dateStart ?? '',
      quotationDateEnd: param?.isClear ? '' : dateRange?.dateEnd ?? '',
      customerId: param?.isClear ? '' : customerId ?? '',
    })
    if (response?.data) {
      setData(response?.data?.data)
      setTotalRows(response?.data?.total)
    }
  }

  const columns = [
    {
      title: "#",
      width: 150,
      align: "center",
      render: (row) => (
        <div>
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => {
              navigate("/quotation/manage", { state: row });
            }}
            style={{
              fontSize: "16px",
              width: 50,
              height: 50,
            }}
          />
          <Button
            type="text"
            icon={<DeleteOutlined />}
            style={{
              fontSize: "16px",
              width: 50,
              height: 50,
              color: "red",
            }}
            onClick={() => {
              Swal.fire({
                title: 'ต้องการลบข้อมูลใช่หรือไม่?',
                text: 'โปรดตรวจสอบและยืนยันว่าต้องการลบข้อมูล จะไม่สามารถนำข้อมูลกลับมาได้!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#dc3545',
                cancelButtonColor: '#040405',
                confirmButtonText: 'ใช่ , ฉันต้องการลบ',
              }).then(async (r) => {
                if (r.isConfirmed) {
                  const { data } = await deleteQuotation(row.quotationId)
                  if (!data?.isSuccess) {
                    Swal.fire(`ล้มเหลว!`, data?.message, 'error')
                  } else {
                    Swal.fire(`สำเร็จ!`, data?.message, 'success').then((result) => {
                      if (result.isConfirmed) {
                        window.location.reload(false)
                      }
                    })
                  }
                }
              })
            }}
          />
        </div>
      ),
    },
    {
      title: "เลขที่เอกสาร",
      dataIndex: "quotationNo",
      key: "quotationNo",
      sorter: true,
      width: 200,
    },
    {
      title: "วันที่",
      dataIndex: "quotationDateText",
      key: "quotationDateText",
      sorter: true,
      width: 200,
    },
    {
      title: "วันหมดอายุ",
      dataIndex: "expireDateText",
      key: "expireDateText",
      sorter: true,
      width: 200,
    },
    {
      title: "ลูกค้า",
      dataIndex: "customerName",
      key: "customerName",
      sorter: true,
      width: 250,
    },
    {
      title: "ประเภทการชำระ",
      dataIndex: "paymentTypeName",
      key: "paymentTypeName",
      sorter: true,
      width: 250,
    },
    {
      title: "ประเภทการขนส่ง",
      dataIndex: "transportName",
      key: "transportName",
      sorter: true,
      width: 250,
    },
    {
      title: "วันรับสินค้า",
      dataIndex: "receiveOrderDateText",
      key: "receiveOrderDateText",
      sorter: true,
      width: 200,
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      sorter: true,
      width: 250,
    },
    {
      title: "ภาษีมูลค่าเพิ่ม % (VAT)",
      dataIndex: "vat",
      key: "vat",
      sorter: true,
      width: 200,
    },
    {
      title: "จำนวนเงินรวมทั้งสิ้น",
      dataIndex: "total",
      key: "total",
      sorter: true,
      width: 200,
      render: (row) => (
        formatNumber(row)
      ),
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "createdDate",
      key: "createdDate",
      sorter: true,
      width: 200,
    },
  ];
  const onChange = (pagination, filters, sorter, extra) => {
    setCurrentPage(pagination?.current)
    setSortField(sorter?.field)
    setSortOrder(sorter?.order)
    setPerPage(pagination?.pageSize);
    setSearch(filters?.search);
  };
  const onFinish = async (data) => {
    fetchData()
  }
  const loadDataDropDown = async () => {
    const { data: customer } = await getCustomerSelect()
    if (customer) {
      setCustomerList(customer)
    }
  }
  const handleDateChange = (dates, dateStrings) => {
    const [start, end] = dates || [null, null]; // Handle null in case of clear
    setDateRange({
      dateStart: start ? dayjs(start).format('YYYY-MM-DD') : null,
      dateEnd: end ? dayjs(end).format('YYYY-MM-DD') : null,
    });
  };
  return (
    <>
      <Row className="row-padding-10">
        <Col className="gutter-row" span={24}>
          <Card title="ค้นหา" bordered={false}>
            <Form form={form} {...formItemLayout} onFinish={onFinish}>
              <Row gutter={24}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={`วันที่`}
                    name="dateRange"
                  >
                    <RangePicker style={{ width: '100%' }} format={'YYYY-MM-DD'} onChange={handleDateChange} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={`ค้นหา`}
                    name="search"
                  >
                    <Input
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      placeholder="ค้นหา เลขที่เอกสาร , หมายเหตุ"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={`ลูกค้า`}
                    name="customerId"
                  >
                    <Select
                      placeholder="กรุณาเลือก"
                      style={{ width: "100%" }}
                      options={customerList}
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      optionFilterProp="children"
                      showSearch
                      onChange={(e) => setCustomerId(e)}
                      value={customerId}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="mt-3">
                <Col span={24}>
                  <div style={{ textAlign: "center" }}>
                    <Space size="middle">
                      <Button
                        htmlType="submit"
                        style={{ backgroundColor: "#125a05", color: '#FFFFFF' }}
                        size="large"
                      >
                        ค้นหา
                      </Button>
                      <Button
                        type="primary"
                        danger
                        size="large"
                        onClick={() => {
                          form.setFieldsValue({
                            supplierId: null,
                            receiverId: null,
                            search: null,
                            dateRange: null,
                          });
                          setCustomerId(null)
                          setSearch(null)
                          setDateRange(null)
                          fetchData({ isClear: true })
                        }}
                      >
                        ล้าง
                      </Button>
                    </Space>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
      <Row className="row-padding-10">
        <Col className="gutter-row" xs={24} lg={12}>
          <Button
            className="ant-btn-submit"
            icon={<PlusCircleOutlined style={{ color: "#FFFFFF" }} />}
            onClick={() => {
              navigate("/quotation/manage");
            }}
            size="large"
            style={{ color: "#FFFFFF", border: "#555843" }}
          >
            เพิ่ม
          </Button>
        </Col>
      </Row>
      <Row className="row-padding-10">
        <Col className="gutter-row" span={24}>
          <Table
            dataSource={data}
            columns={columns}
            scroll={{ x: 500 }}
            onChange={onChange}
            pagination={{
              current: currentPage,
              pageSize: perPage,
              total: totalRows,
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default QuotationList;
