import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DefaultLayout from "./layouts/DefaultLayout";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import CustomerList from "./pages/customer/CustomerList";
import CustomerManage from "./pages/customer/CustomerManage";
import WarehouseList from "./pages/warehouse/WarehouseList";
import WarehouseManage from "./pages/warehouse/WarehouseManage";
import ProductCategoryList from "./pages/product-category/ProductCategoryList";
import ProductCategoryManage from "./pages/product-category/ProductCategoryManage";
import ProductUnitList from "./pages/product-unit/ProductUnitList";
import ProductUnitManage from "./pages/product-unit/ProductUnitManage";
import ProductList from "./pages/product/ProductList";
import ProductManage from "./pages/product/ProductManage";
import SupplierList from "./pages/supplier/SupplierList";
import SupplierManage from "./pages/supplier/SupplierManage";
import CompanyManage from "./pages/company/companyManage";
import UserList from "./pages/user/UserList";
import UserManage from "./pages/user/UserManage";
import ReceiveList from "./pages/warehouse-management/receive/ReceiveList";
import ReceiveManage from "./pages/warehouse-management/receive/ReceiveManage";
import ProductTypeList from "./pages/product-type/ProductTypeList";
import ProductTypeManage from "./pages/product-type/ProductTypeManage";
import ProductGroupList from "./pages/product-group/ProductGroupList";
import ProductGroupManage from "./pages/product-group/ProductGroupManage";
import ProductBrandList from "./pages/product-brand/ProductBrandList";
import ProductBrandManage from "./pages/product-brand/ProductBrandManage";
import StockList from "./pages/stock/StockList";
import StockManage from "./pages/stock/StockManage";
import PurchaseOrderList from "./pages/purchase-order/PurchaseOrderList";
import PurchaseOrderManage from "./pages/purchase-order/PurchaseOrderManage";
import PaymentTypeList from "./pages/payment-type/PaymentTypeList";
import PaymentTypeManage from "./pages/payment-type/PaymentTypeManage";
import TransportList from "./pages/transport/TransportList";
import TransportManage from "./pages/transport/TransportManage";
import QuotationList from "./pages/quotation/QuotationList";
import QuotationManage from "./pages/quotation/QuotationManage";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<DefaultLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/customer" element={<CustomerList />} />
          <Route path="/customer/manage" element={<CustomerManage />} />
          <Route path="/warehouse" element={<WarehouseList />} />
          <Route path="/warehouse/manage" element={<WarehouseManage />} />
          <Route path="/category" element={<ProductCategoryList />} />
          <Route path="/category/manage" element={<ProductCategoryManage />} />
          <Route path="/unit" element={<ProductUnitList />} />
          <Route path="/unit/manage" element={<ProductUnitManage />} />
          <Route path="/type" element={<ProductTypeList />} />
          <Route path="/type/manage" element={<ProductTypeManage />} />
          <Route path="/group" element={<ProductGroupList />} />
          <Route path="/group/manage" element={<ProductGroupManage />} />
          <Route path="/brand" element={<ProductBrandList />} />
          <Route path="/brand/manage" element={<ProductBrandManage />} />
          <Route path="/product" element={<ProductList />} />
          <Route path="/product/manage" element={<ProductManage />} />
          <Route path="/supplier" element={<SupplierList />} />
          <Route path="/supplier/manage" element={<SupplierManage />} />
          <Route path="/company" element={<CompanyManage />} />
          <Route path="/user" element={<UserList />} />
          <Route path="/user/manage" element={<UserManage />} />
          <Route path="/receive" element={<ReceiveList />} />
          <Route path="/receive/manage" element={<ReceiveManage />} />
          <Route path="/stock" element={<StockList />} />
          <Route path="/stock/manage" element={<StockManage />} />
          <Route path="/purchase-order" element={<PurchaseOrderList />} />
          <Route path="/purchase-order/manage" element={<PurchaseOrderManage />} />
          <Route path="/payment-type" element={<PaymentTypeList />} />
          <Route path="/payment-type/manage" element={<PaymentTypeManage />} />
          <Route path="/transport" element={<TransportList />} />
          <Route path="/transport/manage" element={<TransportManage />} />
          <Route path="/quotation" element={<QuotationList />} />
          <Route path="/quotation/manage" element={<QuotationManage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
